
const VuePrototypeExtensions = {
    install (Vue, options) {
        this.nonVueExtensions();
        // Vue.prototype.$removeHTMLtags = function(input) {
        //     return input.replace(/<\/?[^>]+(>|$)/g, "");
        // };
    },

    nonVueExtensions() {
        String.prototype.removeHTMLtags = function() {
            return this.replace(/<\/?[^>]+(>|$)/g, "");
        };
        Array.prototype.findNextIndex = function(startPosition, predicate) {
            const index = this.slice(startPosition).findIndex(predicate);
            return index === -1 ? -1 : index + startPosition;
        };
        //let existingIssues = Object.filter(JSON.parse(getIssues() || "{}"), ([exIssName, exIss]) => issueIdsToFetch.includes(exIssName));
        Object.filter = (obj, predicate) =>
            Object.fromEntries(Object.entries(obj).filter(predicate));

        //Object.filter5(data1, (exIssName, exIss) => issueIdsToFetch.includes(exIssName))
        // Object.filter5 = (obj, predicate) =>
        //     Object.keys(obj).reduce((newObj, key) => {
        //         if (predicate(key)) {
        //             newObj[key] = obj[key];
        //         }
        //         return newObj;
        //     }, {});
    }
};

export default VuePrototypeExtensions


