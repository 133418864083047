import Vue from "vue";
import { API_URL } from "./config";
import ApiService from "./api.service";
import BlobStream from "blob-stream";
import PDFDocument from "pdfkit";
import fs from 'fs'
// use raw-loader explicitly
import Courier from '!!raw-loader!pdfkit/js/data/Courier.afm'
// use raw-loader implicitly (webpack is configured to load *.afm files using raw loader)
import CourierBold from 'pdfkit/js/data/Courier-Bold.afm'
import {ADD_ERROR, ISSUES_LOADING_END} from "../store/store.types";

const PdfService = {
    init(){
        let registerBinaryFiles = function (ctx) {
            ctx.keys().forEach(key => {
                // extracts "./" from beginning of the key
                fs.writeFileSync(key.substring(2), ctx(key))
            });
        };

        let registerAFMFonts = function (ctx) {
            ctx.keys().forEach(key => {
                const match = key.match(/([^/]*\.afm$)/)
                if (match) {
                    // afm files must be stored on data path
                    fs.writeFileSync(`data/${match[0]}`, ctx(key).default)
                }
            });
        };

        // register all files found in assets folder (relative to src)
        registerBinaryFiles(require.context('../assets', true))

        // register AFM fonts distributed with pdfkit
        // is good practice to register only required fonts to avoid the bundle size increase
        registerAFMFonts(require.context('pdfkit/js/data', false, /Helvetica.*\.afm$/))
        registerAFMFonts(require.context('pdfkit/js/data', false, /Times-Roman.*\.afm$/))

        // register files imported directly
        fs.writeFileSync('data/Courier.afm', Courier)
        fs.writeFileSync('data/Courier-Bold.afm', CourierBold)
    },
    downloadUrlImage(imageUrl) {

        // return new Promise((resolve, reject) => {
        //     // Simulate a call to Dropbox or other service that can
        //     // return an image as an ArrayBuffer.
        //     var xhr = new XMLHttpRequest();
        //
        //     // Use JSFiddle logo as a sample image to avoid complicating
        //     // this example with cross-domain issues.
        //     xhr.open("GET", "https://fiddle.jshell.net/img/logo.png", true);
        //
        //     // Ask for the result as an ArrayBuffer.
        //     xhr.responseType = "arraybuffer";
        //
        //     xhr.onload = function (e) {
        //         // Obtain a blob: URL for the image data.
        //         var arrayBufferView = new Uint8Array(this.response);
        //         var blob = new Blob([arrayBufferView], {type: "image/jpeg"});
        //         var urlCreator = window.URL || window.webkitURL;
        //         var imageUrl = urlCreator.createObjectURL(blob);
        //         var img = document.querySelector("#photo");
        //         img.src = imageUrl;
        //     };
        //
        //     xhr.send();
        // });

    },
    //the images are separated from the body, adds them on the semi-right place
    //algoritm to determine the image place --> blendle.com_sources\webpack---\src\js\app\helpers\itemContent.js
    //we have our own simplified version
    addImagesInContentBody(issueArray) {
        var allPromises = [];
        for (const [issueItemID, issueItem] of Object.entries(issueArray)) {
            if(issueItem["_embedded"] && issueItem["_embedded"]["content"] && issueItem["_embedded"]["content"]["body"]) {
                let bodyItems = issueItem["_embedded"]["content"]["body"] || [];
                let imageItems = issueItem["_embedded"]["content"]["images"] || [];
                let totalImageItemsLength = imageItems.length;

                let startPos = 0;
                //logic: find first p, then one before == first image
                //then after each p the next image
                for (let i = 0; i < totalImageItemsLength; i++) {
                    //we assume it will never be -1 (not found)
                    let indexOfP = bodyItems.findNextIndex(startPos,bodyItem => bodyItem.type === 'p');

                    var newImageItemInternal = {
                        type: 'image',
                        href: imageItems[i]["_links"]["medium"]["href"],
                        width: imageItems[i]["_links"]["medium"]["width"],
                        height: imageItems[i]["_links"]["medium"]["height"],
                    };
                    //enclosure the newImageItem, else it loses itself
                    allPromises.push(((newImageItem) => new Promise(function(resolve, reject) {
                        Vue.axios.create({timeout: 20000}).get(newImageItem.href, {responseType: 'arraybuffer'})
                        .then(response => {
                            //const pngBuffer = Buffer.from(response.data);
                            //doc.image(pngBuffer);
                            newImageItem.buffer = response.data;
                            resolve({issueItemID: issueItemID, newImageItem: newImageItem} );
                        })
                        .catch(error => {
                            newImageItem.buffer = null;
                            reject({issueItemID: issueItemID, newImageItem: newImageItem});
                        });
                    }))(newImageItemInternal));
                    //the splice start with one (1) and adds after
                    bodyItems.splice(indexOfP, 0, newImageItemInternal);
                    startPos = indexOfP + 2;
                }
            }
        }
        return Promise.all(allPromises);
    },
    generatePdf(issueArray, titleName, pageCoverUrl) {
        return this.addImagesInContentBody(issueArray).then((result) => {
            if(result && result.length > 0) {
                for (let i = 0; i < result.length; i++) {
                    for (let j = 0; j < issueArray[result[i].issueItemID]["_embedded"]["content"]["body"].length; j++) {
                        if(issueArray[result[i].issueItemID]["_embedded"]["content"]["body"][j].type === 'image' && issueArray[result[i].issueItemID]["_embedded"]["content"]["body"][j].href === result[i].newImageItem.href) {
                            issueArray[result[i].issueItemID]["_embedded"]["content"]["body"][j] = result[i].newImageItem;
                        }
                    }
                }
            }

            if(pageCoverUrl && pageCoverUrl.length > 0) {
                return Vue.axios.create({timeout: 20000}).get(pageCoverUrl, {responseType: 'arraybuffer'})
                    .then(response => {
                        return this.generateAndDownloadPdf(issueArray, titleName, response.data);
                    });
            } else {
                return this.generateAndDownloadPdf(issueArray, titleName, null);
            }
        });
    },
    generateAndDownloadPdf(issueArray, titleName, pageCoverBuffer) {
        return new Promise((resolve, reject) => {
            // create a document and pipe to a blob
            let doc = new PDFDocument({bufferPages: true});
            let stream = doc.pipe(BlobStream());
            let tableOfContentPageNumber = 0;

            let pageNumber = 0;
            doc.on('pageAdded', () => {
                pageNumber++;
                let bottom = doc.page.margins.bottom;
                doc.page.margins.bottom = 0;

                doc.text(`Page ${pageNumber}`,
                    0.5 * (doc.page.width - 100),
                    doc.page.height - 50,
                    {
                        width: 100,
                        align: 'center',
                        lineBreak: false,
                    });

                // Reset text writer position
                doc.text('', 50, 50);
                doc.page.margins.bottom = bottom;
            });
            stream.on('finish', function() {
                let fileBlob = stream.toBlob('application/pdf');
                resolve(fileBlob);
            });

            //add page cover if exists
            if(pageCoverBuffer)  {
                let pageWidthWithoutMargin = doc.page.width - doc.page.margins.left - doc.page.margins.right;
                let pageHeightWithoutMargin = doc.page.height - doc.page.margins.top - doc.page.margins.bottom;
                let imgPropsObj = {fit: [pageWidthWithoutMargin, pageHeightWithoutMargin], align: 'center', valign: 'center'};
                doc.image(pageCoverBuffer, undefined, undefined, imgPropsObj);
                tableOfContentPageNumber = 1;
                doc.addPage();
            }

            doc.addPage(); // table of content empty page
            doc.info["Title"] = titleName;

            let defaultTextOptioins = { align: 'justify' };
            let tableOfContentItems = [];

            doc.registerFont();
            for (const [issueItemID, issueItem] of Object.entries(issueArray)) {
                if(issueItem["_embedded"] && issueItem["_embedded"]["content"] && issueItem["_embedded"]["content"]["body"]) {
                    let tableOfContentItem = null;
                    for(let i = 0; i < issueItem["_embedded"]["content"]["body"].length; i++) {
                        let bodyItem = issueItem["_embedded"]["content"]["body"][i];
                        let bodyText = bodyItem.content ? bodyItem.content.removeHTMLtags() : "";
                        switch (bodyItem.type) {
                            case 'head': //item-title
                            case 'hl1':
                                if(!tableOfContentItem) tableOfContentItem = {bodyText: bodyText, page: pageNumber};
                                doc.font('Helvetica-Bold', 16).text(bodyText, defaultTextOptioins);
                                break;
                            case 'hl2': //item-subtitle
                                if(!tableOfContentItem) tableOfContentItem = {id: bodyText, page: pageNumber};
                                doc.font('Helvetica-Bold', 13).text(bodyText);
                                break;
                            case 'lead': //item-lead
                                doc.font('Helvetica-Bold', 12).text(bodyText);
                                break;
                            case 'byline': //item-byline
                                doc.font('Helvetica-Bold', 12).text(bodyText);
                                break;
                            case 'dateline':
                                break;
                            case 'intro':
                            case 'ph': //item-header
                                doc.font('Helvetica-Bold', 12).text(bodyText);
                                break;
                            case 'default': //item-paragraph
                            case 'p':
                            default:
                                doc.font('Helvetica', 12).text(bodyText).moveDown(1);;
                                break;
                            case 'streamer': //streamerVisitor
                                doc.image(bodyText);
                                break;
                            case 'image':
                                if(bodyItem.buffer) {
                                    //we want the max image height max half of the page
                                    let maxImageHeight = +(doc.page.height / 2.5).toFixed(2);
                                    let maxImageWidth = doc.page.width - doc.page.margins.left - doc.page.margins.right;
                                    let roomLeftBottom = doc.page.height - doc.y - 10;
                                    let imgPropsObj = {width: bodyItem.width, height: bodyItem.height};
                                    //the default aspect ratio is width compared to height
                                    let imagAspectRatioWidthToHeight = +(bodyItem.width / bodyItem.height).toFixed(3);
                                    let imagAspectRatioHeightToWidth = +(bodyItem.height / bodyItem.width).toFixed(3);
                                    //if 1/4 of the room is left, not enough room, go to next page
                                    if(roomLeftBottom < (doc.page.height / 5) || bodyItem.height > roomLeftBottom) {
                                        doc.addPage();
                                        roomLeftBottom = doc.page.height - doc.y - 10;
                                    }

                                    let newImageHeight = Math.min(roomLeftBottom, bodyItem.height, maxImageHeight);
                                    let newImageWidth = +(newImageHeight * imagAspectRatioWidthToHeight);

                                    //if the image is to wide for the page, we want to shrinkt on the width
                                    if(newImageWidth > maxImageWidth) {
                                        newImageWidth = Math.min(maxImageWidth, bodyItem.width);
                                        imgPropsObj = {width: newImageWidth.toFixed(2) , height: +(newImageWidth * imagAspectRatioHeightToWidth)};
                                    } else { //else we want to shrink on the height
                                        //the roomLeftBottom is the height of the image needs to be
                                        imgPropsObj = {width: +(newImageHeight * imagAspectRatioWidthToHeight).toFixed(2) , height: newImageHeight};
                                    }

                                    doc.image(bodyItem.buffer, undefined, undefined, imgPropsObj);
                                    doc.moveDown(1);
                                }
                                break;
                            case 'image-grid':
                                break;
                            case 'youtube-video':
                                break;
                            case 'image-meta':
                                break;
                        }

                        /*
                        templateMapper.set('kicker', < p className = "item-kicker" / > );
    templateMapper.set('head', < h1 className = "item-title" / > );
    templateMapper.set('hl1', < h1 className = "item-title" / > );
    templateMapper.set('hl2', < h2 className = "item-subtitle" / > );
    templateMapper.set('lead', < p className = "item-lead" / > );
    templateMapper.set('byline', < p className = "item-byline" / > );
    templateMapper.set('dateline', < p className = "item-dateline" / > );
    templateMapper.set('intro', < p className = "item-intro" / > );
    templateMapper.set('ph', < h3 className = "item-header" / > );
    templateMapper.set('p', < p className = "item-paragraph" / > );
    templateMapper.set('streamer', streamerVisitor);
    templateMapper.set('image-meta', < div className = "item-image-meta" / > );
    templateMapper.set('default', < p className = "item-default" / > );
    templateMapper.set('image', imageVisitor);
    templateMapper.set('image-grid', imageGridVisitor);
    templateMapper.set('youtube-video', youtubeVideoVisitor);
                        * */
                    }
                    //doc.moveDown(5);
                    doc.addPage();

                    if(tableOfContentItem && tableOfContentItem.id !== null) {
                        tableOfContentItems.push(tableOfContentItem);
                    }
                }
            }

            //adds the table of content
            if(tableOfContentItems.length) {
                doc.switchToPage(tableOfContentPageNumber);
                doc.font('Helvetica-Bold', 18).text('Table of Content');

                for (let i = 0; i < tableOfContentItems.length; i++) {
                    let iPlusOne = i+1;
                    if(iPlusOne % 2 === 0) {
                        doc.fillColor('#606060');
                    } else {
                        doc.fillColor('black');
                    }
                    doc.font('Helvetica', 12).text(iPlusOne + ') ' + tableOfContentItems[i].bodyText, { align: 'justify', link: +tableOfContentItems[i].page});
                }
            }

            doc.end();
        });//end promise
    }

};

export default PdfService;