import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            component: () => import("../views/Home"),
            children: [
                {
                    path: "",
                    name: "home",
                    component: () => import("../views/HomeGlobal")
                }
            ]
        },
        {
            name: "login",
            path: "/login",
            component: () => import("../views/Login")
        },
        {
            name: "magazines",
            path: "/magazines",
            component: () => import("../views/Magazines"),
            meta: {
                requiresAuth: true
            },
        },
        {
            name: "magazine",
            path: "/magazine/:providerid",
            component: () => import("../views/MagazineMetaData"),
            meta: {
                requiresAuth: true
            },
        },

        // {
        //     path: "",
        //     component: () => import("/views/Magazines"),
        //     children: [
        //         {
        //             name: "magazine",
        //             path: "/@:providerid",
        //             component: () => import("/views/Magazine")
        //         },
        //         {
        //             name: "issue",
        //             path: "/@:issueid",
        //             component: () => import("/views/Issue")
        //         }
        //     ]
        // }
    ]
});