import Vue from "vue";
import {
    LOGIN,
    LOGOUT,
    REGISTER,
    CHECK_AUTH,
    UPDATE_USER, ADD_ERROR
} from "./store.types";
import { SET_AUTH, PURGE_AUTH, SET_ERROR } from "./store.types";
import ApiService from "../common/api.service";
import TokenService from "../common/token.service";

const state = {
    errors: null,
    user: {},
    isAuthenticated: !!TokenService.getToken()
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise(resolve => {
            ApiService.postMessage('LOGIN', { USERNAME: credentials.email, PASSWORD: credentials.password })
                .then( (response) => {
                    context.commit(SET_AUTH, response.data.DATA);
                    resolve(response.data.DATA);
                })
                .catch( (response) => {
                    context.commit(PURGE_AUTH);
                    //context.commit(SET_ERROR, response.data.errors);
                    context.commit(SET_ERROR, {"LOGIN_ERROR": error});
                });
        });
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
        ApiService.postMessage("LOGOUT", {})
            .then( (response) => {

            })
            .catch( (response) => {

            });
    },
    [CHECK_AUTH](context) {
        if (TokenService.getToken()) {
            return new Promise((resolve, reject) => {
                ApiService.postMessage("ISLOGGEDIN", {})
                    .then( (response) => {
                        context.commit(SET_AUTH, response.data.DATA);
                        resolve(true);
                    })
                    .catch( (response) => {
                        context.commit(PURGE_AUTH);
                        context.commit(SET_ERROR, response.data.errors);
                        reject(false);
                    });
            });
        } else {
            context.commit(PURGE_AUTH);
            return Promise.reject(false)
        }
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [ADD_ERROR](state, error) {
        if(!error) return;
        if(!state.errors) state.errors = {};
        for (const [key, value] of Object.entries(error)) {
            Vue.set(state.errors, key, value);
            //state.errors[key] = value;
        }
    },
    [SET_AUTH](state, loginData) {
        state.isAuthenticated = true;
        state.user = {username: loginData.username};
        state.errors = {};
        TokenService.saveToken(loginData.token);
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        TokenService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};