
const TOKEN_NAME = "token_name";

export const getToken = () => {
    return window.localStorage.getItem(TOKEN_NAME);
};

export const saveToken = token => {
    window.localStorage.setItem(TOKEN_NAME, token);
};

export const destroyToken = () => {
    window.localStorage.removeItem(TOKEN_NAME);
};

export default { getToken, saveToken, destroyToken };