import Vue from 'vue'
import App from "./App.vue";

import store from "./store";
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import router from "./router";
import ErrorFilter from "./common/error.filter";
import { CHECK_AUTH } from "./store/store.types";
import ApiService from "./common/api.service";
import PdfService from "./common/pdf.service";
import VuePrototypeExtensions from "./common/vue.extensions";

Vue.use(Buefy, {
    defaultIconPack: 'fas',
    defaultContainerElement: '.container',
});
Vue.filter("error", ErrorFilter);

VuePrototypeExtensions.install(Vue);

ApiService.init();
PdfService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        Promise.all([store.dispatch(CHECK_AUTH)]).then((result) => {
            if(result && result[0] === true) {
                next();
            } else {
                next({
                    path: '/login',
                    params: { nextUrl: to.fullPath }
                });
            }

        }).catch((error) => {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            });
        });
    } else {
        next();
    }
});

new Vue({
    store,
    router,
    render: h => h(App)
 }).$mount('#app')