export const ARTICLE_PUBLISH = "publishArticle";
export const ARTICLE_DELETE = "deleteArticle";
export const ARTICLE_EDIT = "editArticle";
export const ARTICLE_EDIT_ADD_TAG = "addTagToArticle";
export const ARTICLE_EDIT_REMOVE_TAG = "removeTagFromArticle";
export const ARTICLE_RESET_STATE = "resetArticleState";
export const CHECK_AUTH = "checkAuth";
export const COMMENT_CREATE = "createComment";
export const COMMENT_DESTROY = "destroyComment";
export const FAVORITE_ADD = "addFavorite";
export const FAVORITE_REMOVE = "removeFavorite";
export const FETCH_ARTICLE = "fetchArticle";
export const FETCH_PROVIDERS = "fetchProviders";
export const FETCH_PROVIDER_METADATA = "fetchProviderMetadata";
export const FETCH_PROFILE = "fetchProfile";
export const FETCH_PROFILE_FOLLOW = "fetchProfileFollow";
export const FETCH_PROFILE_UNFOLLOW = "fetchProfileUnfollow";
export const FETCH_TAGS = "fetchTags";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";


export const PROVIDERS_FETCH_END = "providersFetchEnd";
export const PROVIDERS_FETCH_START = "providersFetchStart";
export const ISSUES_LOADING_END = "issuesLoadingEnd";
export const ISSUES_LOADING_START = "issuesLoadingStart";

export const FETCH_MONTH_ISSUES = "fetchMonthIssues";
export const FETCH_ISSUES = "fetchIssues";

export const STORAGE_ISSUES = "issuesStorage";


export const PURGE_AUTH = "logOut";
export const SET_ARTICLE = "setArticle";
export const SET_AUTH = "setUser";
export const SET_COMMENTS = "setComments";
export const SET_ERROR = "setError";
export const ADD_ERROR = "addError";
export const SET_PROFILE = "setProfile";
export const SET_TAGS = "setTags";
export const TAG_ADD = "addTag";
export const TAG_REMOVE = "removeTag";
export const UPDATE_ARTICLE_IN_LIST = "updateAricleInList";
export const RESET_STATE = "resetModuleState";