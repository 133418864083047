import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "./config";
import TokenService from "./token.service";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.timeout = 100000; //in MS

    //Vue.axios.defaults.headers = {'X-Custom-Header': 'foobar'};
  },

  // setHeader() {
  //   Vue.axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Token ${TokenService.getToken()}`;
  // },

  // get(resource) {
  //   return Vue.axios.get(`${resource}/${slug}`).catch(error => {
  //     throw new Error(`[RWV] ApiService ${error}`);
  //   });
  // },

  post(data) {
    //return Vue.axios.post(`${resource}`, params);
    return Vue.axios.post(``, data, {withCredentials: true});
  },

  postMessage(action, data, options = null) {
    //return Vue.axios.post(`${resource}`, params);
    return Vue.axios.post(``,          {
      "ACTION": action.toUpperCase(),
      "DATA": data
    }, Object.assign({withCredentials: true }, options || {}));
  },
  //
  // update(resource, slug, params) {
  //   return Vue.axios.put(`${resource}/${slug}`, params);
  // },
  //
  // put(resource, params) {
  //   return Vue.axios.put(`${resource}`, params);
  // },
  //
  // delete(resource) {
  //   return Vue.axios.delete(resource).catch(error => {
  //     throw new Error(`[RWV] ApiService ${error}`);
  //   });
  // }
};
/*
Vue.axios.get(api).then((response) => {
  console.log(response.data)
})
 
this.axios.get(api).then((response) => {
  console.log(response.data)
})
 
this.$http.get(api).then((response) => {
  console.log(response.data)
})*/

export default ApiService;


// export const TagsService = {
//   get() {
//     return ApiService.get("tags");
//   }
// };
