<template>
  <div id="app">
    <main>
      <Header />
        <section>
          <div class="container clearfix">
            <div class="main-content">
              <router-view></router-view>
            </div>
          </div>
        </section>
      <Footer />
    </main>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
	Header,
    Footer
  }
};
</script>

<style>
  .container {
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;
  }
  .main-content {
    padding: 3rem;
  }
  .clearfix {
    overflow: auto;
  }
</style>