import {
    FETCH_PROVIDERS, PROVIDERS_FETCH_START, PROVIDERS_FETCH_END, SET_ERROR,
    FETCH_PROVIDER_METADATA, ISSUES_LOADING_START, ISSUES_LOADING_END, FETCH_MONTH_ISSUES, ADD_ERROR, FETCH_ISSUES,
    STORAGE_ISSUES
} from "./store.types";
import ApiService  from "../common/api.service"

const state = {
    providers: [],
    isLoading: true,
    loadingCounter: 0,
};


const getIssues = () => {
    return JSON.parse(window.localStorage.getItem(STORAGE_ISSUES) || "{}");
};

const saveMergeIssues = issueContainer => {
    //merge the existing issues with the new issue
    let issueContainerObj = typeof(issueContainer) === 'string' ? JSON.parse(issueContainer) : issueContainer;
    window.localStorage.setItem(STORAGE_ISSUES, JSON.stringify(Object.assign(getIssues(), issueContainerObj)));
};

const destroyIssues = () => {
    window.localStorage.removeItem(STORAGE_ISSUES);
};

const getters = {
    providersCount(state) {
        return state.providers ? state.providers.length : 0;
    },
    providers(state) {
        return state.providers;
    },
    isLoading(state) {
        return state.isLoading;
    }
};

const actions = {
    [FETCH_PROVIDERS]({ commit }, params) {
        commit(ISSUES_LOADING_START);
        let fetchAll = params && params.fetchAll ? params.fetchAll : false;
        return ApiService.postMessage("GETPROVIDERS", {FETCHALL: fetchAll})
            .then(( response ) => {
                commit(PROVIDERS_FETCH_END, response.data && response.data.DATA ? response.data.DATA : []);
                commit(ISSUES_LOADING_END, []);
            })
            .catch(error => {
                commit(ISSUES_LOADING_END, []);
                commit(ADD_ERROR, {"FETCH_PROVIDERS_ERROR": error});
                //throw new Error(error);
            });
    },
    [FETCH_PROVIDER_METADATA]( context , params) {
        context.commit(ISSUES_LOADING_START);
        let promises = [];
        if(!(context.state.providers && context.state.providers.length > 0)){
            promises.push(context.dispatch(FETCH_PROVIDERS));
        }
        promises.push(ApiService.postMessage("GETPROVIDERMETADATA", {"PROVIDERID": params.providerId})
            .then(( response ) => {
                context.commit(ISSUES_LOADING_END, []);
                return response.data && response.data.DATA ? response.data.DATA[params.providerId] : [];
            })
            .catch(error => {
                context.commit(ISSUES_LOADING_END, []);
                context.commit(ADD_ERROR, {"FETCH_PROVIDER_METADATA_ERROR": error});
                //throw new Error(error);
                return [];
            }));
        return new Promise((resolve, reject) => {
            Promise.all(promises)
                .then(( data ) => {
                    resolve(data && data[1] ? data[1] : data[0]);
                })
                .catch(( error ) => {
                    context.commit(SET_ERROR, error);
                    reject(error);
                });
        });
    },
    [FETCH_MONTH_ISSUES](context, params) {
        context.commit(ISSUES_LOADING_START);
        return ApiService.postMessage("GETMONTHISSUES", {"PROVIDERID": params.providerId, "YEAR": params.year, "MONTH": params.month, "DAY": params.day})
            .then(( response ) => {
                context.commit(ISSUES_LOADING_END, []);
                return response.data && response.data.DATA ? response.data.DATA : {};
            })
            .catch(error => {
                context.commit(ISSUES_LOADING_END, []);
                context.commit(ADD_ERROR, {"FETCH_ISSUE_METADATA_ERROR": error});
                return [];
            });
    },
    [FETCH_ISSUES](context, params) {
        context.commit(ISSUES_LOADING_START);
        let issueIdsToFetch = params.issueIds;
        let allExistingIssuesInLocalStorage = getIssues();

        //we only want the issues provided as input, not all in local storage, so we filter then by only the ones by the id's provided
        let existingIssues = Object.filter(allExistingIssuesInLocalStorage, ([exIssName, exIss]) => issueIdsToFetch.includes(exIssName));
        //keys only in an array
        let existingIssuesKeys = Object.entries(existingIssues);
        issueIdsToFetch = issueIdsToFetch.filter((index, itemIdToFetch) => !(itemIdToFetch in existingIssuesKeys));

        if(issueIdsToFetch && issueIdsToFetch.length > 0) {
            return ApiService.postMessage("GETISSUES", {"ISSUEIDS": issueIdsToFetch }, {timeout: 100000})
                .then(( response ) => {
                    context.commit(ISSUES_LOADING_END, []);
                    let issueData = response.data && response.data.DATA ? response.data.DATA : [];
                    if(Object.keys(issueData).length > 0 && response.data.SUCCEEDED) {
                        for (const [issueItemID, issueItem] of Object.entries(issueData)) {
                            existingIssues[issueItemID] = issueItem;
                        }
                    } else {
                        let errorMessage = "" + issueIdsToFetch.length + " issues fetched, bud no result!";
                        if(response.data && response.data.ERROR && response.data.ERROR.TYPE) {
                            errorMessage = response.data.ERROR.TYPE + ": " + response.data.ERROR.MESSAGE;
                        }
                        throw new Error(errorMessage);
                    }

                    saveMergeIssues(existingIssues);
                    return existingIssues;
                })
                .catch(errorRequest => {
                    let errorMessage = errorRequest;
                    if(errorRequest.response && errorRequest.response.data && errorRequest.response.data.ERROR) {
                        errorMessage = errorRequest.response.data.ERROR.TYPE + ": " + errorRequest.response.data.ERROR.MESSAGE;
                    }
                    context.commit(ISSUES_LOADING_END, []);
                    context.commit(ADD_ERROR, {"FETCH_ISSUES_ERROR": errorMessage});
                    return [];
                });
        } else {
            context.commit(ISSUES_LOADING_END, []);
            return Promise.resolve(existingIssues);
        }

    },
};

const mutations = {
    [PROVIDERS_FETCH_END](state, providers) {
        state.providers = providers;
    },
    [ISSUES_LOADING_START](state) {
        state.loadingCounter += 1;
        state.isLoading = true;
    },
    [ISSUES_LOADING_END](state) {
        state.loadingCounter -= 1;
        if(state.loadingCounter < 1)
            state.isLoading = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};